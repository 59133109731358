import React, { useEffect } from "react"
import * as styles from "./login.module.css"
import Logo from "components/tools/logo.js"
import { getJson, getIsApp } from "utils"
import { navigate } from "gatsby-link"
import customProtocolCheck from "custom-protocol-check"
import api from "apis"
// 1. 앱 다운로드 되어 있는 상태의 회원 -> 피처서가 앱 실행

// 2.  2) 앱 다운로드 되어 있지 않은 상태의 회원 -> H02-피치서가-프론트-pc뷰어다운로드01 페이지 이동 후 다운로드 진행 후 앱 실행

const Login = () => {
  console.log(globalThis.navigator?.userAgent)

  console.log(process.env.REGISTRY_NAME)

  const access_token = getJson("access_token")

  const onExe = () => {
    customProtocolCheck(
      `${process.env.REGISTRY_NAME}://`,
      () => navigate("/pcViewer/"),
      () => {
        document.location = `${process.env.REGISTRY_NAME}://`
      },
      1000
    )
  }

  return (
    <div className={styles.con}>
      <div className={styles.header}>
        <Logo w={160} h={35.67} white={true} navCancel={!getIsApp()} />
        {/* <Logo w={188} h={41} white={true} navCancel={!getIsApp()} /> */}
      </div>
      <div className={styles.main}>
        <div>
          <p>{access_token?.name}</p>
          님,
          <br />
          피치서가 PC뷰어에서
          <br /> 쉬운 글 <p>스토리</p>를 읽어보세요.
        </div>
      </div>
      <div
        className={`longBtn ${styles.btn}`}
        onClick={() => (getIsApp() ? navigate("/front") : onExe())}
      >
        {getIsApp() ? "피치서가 시작하기" : "피치서가 PC뷰어 다운로드"}
      </div>
    </div>
  )
}

export default Login
